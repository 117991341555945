import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

function MiNavBar({ activo }) {
  return (
    <Navbar
      bg="dark"
      data-bs-theme="dark"
      style={{ position: "sticky", top: 0, zIndex: 10 }}
    >
      <Navbar.Brand as={Link} to="/" style={{ color: "#f1f1f1" }}>
        Devmat
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        style={{ color: "white" }}
      />
      <Navbar.Collapse id="basic-navbar-nav" style={{ color: "white" }}>
        <Nav className="mr-auto" style={{ color: "white", marginLeft: 20 }}>
          <Nav.Link as={Link} to="/blog" style={{ color: "#f1f1f1" }}>
            Blog
          </Nav.Link>
          <Nav.Link as={Link} to="/proyectos" style={{ color: "#f1f1f1" }}>
            Proyectos
          </Nav.Link>

          <Nav.Link
            href="#sobremi"
            style={{ color: "#f1f1f1" }}
            disabled={activo}
          >
            Sobre mí
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MiNavBar;
