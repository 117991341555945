import React from "react";
import MiNavBar from "./Navbar";
import UncontrolledExample from "./Carrusel";
import Sobremi from "./Sobremi";
import ListadeTeconologias from "./TecnologiasUtilizadas";
import Experiencia from "./Miexperiencia";
export default function Inicio({ setActivar }) {
  return (
    <>
      <div className="App">
        <MiNavBar activo={setActivar} />
        <UncontrolledExample />
        <Sobremi />
        <ListadeTeconologias />
        <Experiencia />
      </div>
    </>
  );
}
