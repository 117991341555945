import { useEffect, useState } from "react";

export default function SolicitudGithubRepo() {
  const [repos, setRepos] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://api.github.com/users/michaelAT01/repos",
        {
          method: "GET",
          headers: {
            "Content-type": "application/json;charset=UTF-8",
          },
        }
      );
      const json = (await response).json();
      setRepos(json);
    };
    fetchData();
  }, []);
  return { repo: repos };
}
