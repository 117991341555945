import "bootstrap/dist/css/bootstrap.css";
import { useState } from "react";
import Rutas from "./components/Rutas";
import "./App.css";

function App() {
  const [activar, setActivar] = useState(true);

  return (
    <div className="App">
      <Rutas setActivar={setActivar} />
    </div>
  );
}

export default App;
