import React from "react";
import MiNavBar from "./Navbar";
import proximamente from "../assets/proximamente.jpg";
import Image from "react-bootstrap/Image";

export default function Blog() {
  return (
    <>
      <MiNavBar activo={true} />
      <Image src={proximamente} thumbnail style={{ height: 650 }} />
    </>
  );
}
