import React from "react";

import { Container } from "react-bootstrap";
export default function Experiencia() {
  return (
    <>
      <Container
        fluid="sm"
        style={{
          marginTop: 30,
        }}
      >
        <h3 style={{ marginBottom: 20 }}>Empresas donde labore </h3>
        <p>
          <a href="https://www.tecoop.cr/" target="_blank">
            Tecoop
          </a>
          <br />
          <a href="https://www.ucr.ac.cr/" target="_blank">
            Universidad de Costa Rica
          </a>
          <br />
          <a href="https://www.municlimon.go.cr/" target="_blank">
            Municipalidad de Limón
          </a>
        </p>
      </Container>
    </>
  );
}
