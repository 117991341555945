import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Image } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import angularimg from "../assets/angularimg.png";
import boostrap from "../assets/boostrap.png";
import netc from "../assets/netc.png";
import github from "../assets/github.png";
import java from "../assets/java.png";
import nodejs from "../assets/nodejs.png";
import mysql from "../assets/mysql.png";

function ListadeTeconologias() {
  return (
    <Container style={{ marginTop: 100, marginBottom: 80 }}>
      <h2>Tecnologías</h2>
      <Row>
        <Col xs={{ order: 2 }}>
          <Image src={angularimg} thumbnail />
        </Col>
        <Col xs={{ order: 3 }}>
          <Image src={boostrap} thumbnail />
        </Col>
        <Col xs={{ order: 4 }}>
          <Image src={github} thumbnail />
        </Col>
        <Col xs={{ order: 5 }}>
          <Image src={netc} thumbnail />
        </Col>
        <Col xs={{ order: 1 }}>
          <Image src={nodejs} thumbnail />
        </Col>
        <Col xs={{ order: 6 }}>
          <Image src={java} thumbnail />
        </Col>
        <Col xs={{ order: 7 }}>
          <Image src={mysql} thumbnail />
        </Col>
      </Row>
    </Container>
  );
}

export default ListadeTeconologias;
