import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Sobremi from "./Sobremi";
import Experiencia from "./Miexperiencia";
import UncontrolledExample from "./Carrusel";
import Inicio from "./IndexPrincipal";
import Proyectos from "./Miproyectos";
import Blog from "./Blog";

export default function Rutas({ setActivar }) {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Inicio setActivar={false} />} />
        <Route path="/carrusel" element={<UncontrolledExample />} />
        <Route path="/experiencia" element={<Experiencia />} />
        <Route path="/proyectos" element={<Proyectos />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="*" element={<Sobremi />} />
      </Routes>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Rutas />);
