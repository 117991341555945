import React from "react";
import { useState } from "react";
import MiNavBar from "./Navbar";
import SolicitudGithubRepo from "../Hooks/TraerRepositorioGithub";
import Table from "react-bootstrap/Table";

export default function Proyectos() {
  const [repositorios, setRepositorios] = useState(null);
  const { repo } = SolicitudGithubRepo();
  if (repo)
    repo.then((valor) => {
      console.log(valor); // Esto imprimirá tu Array(4)
      setRepositorios(valor);
    });

  return (
    <>
      <MiNavBar activo={true} />
      <div
        style={{ marginLeft: "5%", marginRight: "20%", textAlign: "center" }}
      >
        <p style={{ fontSize: 28 }}>Proyectos en Github</p>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Nombre</th>
              <th>Colaboradores</th>
              <th>Sitio web</th>
            </tr>
          </thead>
          <tbody>
            {repositorios ? (
              repositorios.map((dato) => (
                <tr key={dato.id}>
                  <td>{dato.id}</td>
                  <td>{dato.name}</td>
                  <td>{dato.collaborators_url}</td>
                  <td>{dato.hooks_url}</td>
                </tr>
              ))
            ) : (
              <p>No hay nada</p>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
}
