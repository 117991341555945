import React from "react";

export default function Sobremi() {
  return (
    <div id="sobremi" className="container-sm" style={{ marginTop: 50 }}>
      <header>
        <h1>Sobre mí</h1>
      </header>
      <article>
        Mi nombre es Michael Arauz, me gusta bastante la programación, el
        ciclísmo, y la música. Soy un recién graduado de la carrera de
        Informatica Empresarial, una persona con ganas de aportar.
      </article>
    </div>
  );
}
