import Carousel from "react-bootstrap/Carousel";
import React, { useEffect } from "react";
//import ExampleCarouselImage from "./ImagenesCarrusel";
import Aos from "aos";
import "aos/dist/aos.css";
import yoga from "../assets/yopa.jpg";

import miDos from "../assets/miDos.png";
import miTres from "../assets/miTres.png";
function UncontrolledExample() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <Carousel
      style={{
        marginTop: 50,
        border: "3px transparent gray",
        borderRadius: 10,
        width: "50%",

        marginLeft: "25%",
        flex: 1,
      }}
      data-aos="fade-down"
      data-interval={500}
    >
      <Carousel.Item>
        <img
          src={miTres}
          alt="My pictures uno"
          style={{ width: "60%", height: "40%" }}
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={miDos}
          alt="My pictures dos"
          style={{ width: "60%", height: "40%" }}
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={yoga}
          alt="My pictures tres"
          style={{ width: "60%", height: "40%" }}
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;
